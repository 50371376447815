import React from 'react';

import './style.css';

const logo = require('../../static/logo.png');

const ClosedBlock = () => (
  <div className="closed-block">
    <img src={logo} alt="Logo Daan's Droomijs" />
    <h1>Daan's droomijs is gesloten voor de feestdagen</h1>
    <p>
      Fijne feestdagen en volg ons op <a href="https://www.facebook.com/DaansDroomijs">Facebook</a> voor nieuwe ontwikkelingen.
    </p>
  </div>
);

export default ClosedBlock;
