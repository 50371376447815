import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { CircleSpinner } from "react-spinners-kit";
import axios from 'axios';

import './style.scss';

import { orderAPI } from '../../../services/util';
import { formatPrice } from '../../../services/util';

class OrderForm extends Component {
  static propTypes = {
    cartProducts: PropTypes.array.isRequired,
    cartTotal: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    name: '',
    phone: '',
    email: '',
    isLoading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { cartTotal, cartProducts } = this.props;

    this.setState({ isLoading: true });

    const order = {
      customer: {
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
      },
      products: cartProducts,
      totals: cartTotal,
    }

    axios({
      method: 'POST',
      url: orderAPI,
      data:  order
    }).then((resp) => {
      if(resp.status === 200) {
        console.log(`Order created: ${resp.data.order_id}`);
        this.props.onSubmit();
      }
    })
  }

  onNameChange(event) {
	  this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value})
  }

  onPhoneChange(event) {
	  this.setState({phone: event.target.value})
  }

  render() {
    const { cartTotal } = this.props;
    const { isLoading } = this.state;

    const classes = ['order-form'];

    if (this.state.loading) {
      classes.push('disabled');
    }

    return (
      <div className="float-cart__content">
        <form className="order-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
        <div className="float-cart__header">
          <span className="header-title">Uw gegevens</span>
        </div>

        <div className="float-cart__shelf-container">
          <div className="form-group">
            <label htmlFor="name">Naam</label>
            <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} required disabled={isLoading} />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} required disabled={isLoading} />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Telefoon</label>
            <input type="phone" className="form-control" id="phone" value={this.state.phone} onChange={this.onPhoneChange.bind(this)} required disabled={isLoading} />
          </div>
        </div>

        <div className="float-cart__footer">
          <div className="sub">TOTAAL</div>
          <div className="sub-price">
            <p className="sub-price__val">
              {`${cartTotal.currencyFormat} ${formatPrice(
                cartTotal.totalPrice,
                cartTotal.currencyId
              )}`}
            </p>
          </div>
          {isLoading && (
            <div className="spinner-container">
              <div className="spinner"><CircleSpinner className="spinner" size={20} /></div>
              <span>Bestelling wordt verstuurd...</span>
            </div>
            )}
            {!isLoading && (
            <button type="submit" className="btn btn-primary buy-btn">Versturen</button>
            )}
        </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartProducts: state.cart.products,
  cartTotal: state.total.data,
});

export default connect(
  mapStateToProps,
  { }
)(OrderForm);
