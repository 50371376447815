import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './style.scss';

const party = require('../../../static/party.png');

class Thanks extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
  };

  render() {
    return (
      <div className="float-cart__content">
        <div className="float-cart__header">
          <span className="header-title">Bedankt voor je bestelling</span>
        </div>
        <div className="float-cart__shelf-container">
          <div className="thanks">
            <img src={party} alt="Party!!" />
            <div>
              We versturen de bevestiging naar je e-mailadres.
            </div>
          </div>
        </div>
        <div className="float-cart__footer">
          <div onClick={() => this.props.onSubmit()} className="buy-btn">
            Sluiten
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  { }
)(Thanks);
