import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadCart, removeProduct, changeProductQuantity } from '../../../services/cart/actions';
import { updateCart } from '../../../services/total/actions';
import CartProduct from '../CartProduct';
import { formatPrice } from '../../../services/util';

import './style.scss';

class ShoppingCart extends Component {
  static propTypes = {
    loadCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    cartProducts: PropTypes.array.isRequired,
    newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object,
    changeProductQuantity: PropTypes.func,
    productToChange: PropTypes.object,
    cartTotal: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onOpenFloatCart: PropTypes.func.isRequired,
  };

  state = {
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.newProduct !== this.props.newProduct) {
      this.addProduct(nextProps.newProduct);
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }

    if (nextProps.productToChange !== this.props.productToChange) {
      this.changeProductQuantity(nextProps.productToChange);
    }
  }

  addProduct = product => {
    const { cartProducts, updateCart } = this.props;
    let productAlreadyInCart = false;

    cartProducts.forEach(cp => {
      if (cp.id === product.id) {
        cp.quantity += product.quantity;
        productAlreadyInCart = true;
      }
    });

    if (!productAlreadyInCart) {
      cartProducts.push(product);
    }

    updateCart(cartProducts);
    this.props.onOpenFloatCart();
  };

  removeProduct = product => {
    const { cartProducts, updateCart } = this.props;

    const index = cartProducts.findIndex(p => p.id === product.id);
    if (index >= 0) {
      cartProducts.splice(index, 1);
      updateCart(cartProducts);
    }
  };

  changeProductQuantity = changedProduct => {
    const { cartProducts, updateCart } = this.props;

    const product = cartProducts.find(p => p.id === changedProduct.id);
    product.quantity = changedProduct.quantity;
    if (product.quantity <= 0) {
      this.removeProduct(product);
    }
    updateCart(cartProducts);
  }

  render() {
    const { cartTotal, cartProducts, removeProduct, changeProductQuantity } = this.props;

    const products = cartProducts.map(p => {
      return (
        <CartProduct product={p} removeProduct={removeProduct} changeProductQuantity={changeProductQuantity} key={p.id} />
      );
    });

    let classes = ['float-cart'];

    if (!!this.state.isOpen) {
      classes.push('float-cart--open');
    }

    return (
      <div className="float-cart__content">
        <div className="float-cart__header">
          <span className="bag">
            <span className="bag__quantity">{cartTotal.productQuantity}</span>
          </span>
          <span className="header-title">Winkelmandje</span>
        </div>

        <div className="float-cart__shelf-container">
          {products}
          {!products.length && (
            <p className="shelf-empty">
              Je winkelmandje is leeg<br />
              :)
            </p>
          )}
        </div>

        <div className="float-cart__footer">
          <div className="sub">TOTAAL</div>
          <div className="sub-price">
            <p className="sub-price__val">
              {`${cartTotal.currencyFormat} ${formatPrice(
                cartTotal.totalPrice,
                cartTotal.currencyId
              )}`}
            </p>
            <small className="sub-price__installment">
              {!!cartTotal.installments && (
                <span>
                  {`OR UP TO ${cartTotal.installments} x ${
                    cartTotal.currencyFormat
                  } ${formatPrice(
                    cartTotal.totalPrice / cartTotal.installments,
                    cartTotal.currencyId
                  )}`}
                </span>
              )}
            </small>
          </div>
          {cartTotal.totalPrice > 0 && (
          <div onClick={() => this.props.onSubmit()} className="buy-btn">
            Bestellen
          </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange,
  cartTotal: state.total.data,
});

export default connect(
  mapStateToProps,
  { loadCart, updateCart, removeProduct, changeProductQuantity }
)(ShoppingCart);
