import React, { Component } from 'react';

import Shelf from '../Shelf';
// import Filter from '../Shelf/Filter';
// import GithubCorner from '../github/Corner';
import FloatCart from '../FloatCart';
import HomeBlock from '../HomeBlock';
import FooterBlock from '../FooterBlock';
import ClosedBlock from '../ClosedBlock';

class App extends Component {

  state = {
    isClosed: this.calculateClosed(),
  };

  calculateClosed() {
    const closed = new Date('12/22/2020 16:00');
    return new Date() > closed;
  }

  render() {
    const { isClosed } = this.state;

    if (isClosed) {
      return (
        <React.Fragment>
          <ClosedBlock />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <HomeBlock />
          <main>
            <Shelf />
          </main>
          <FooterBlock />
          <FloatCart />
        </React.Fragment>
      )
    }
  }
}

export default App;
