import React from 'react';

import './style.css';

const logo = require('../../static/logo.png');

const HomeBlock = () => (
  <div className="home-block">
    <img src={logo} alt="Logo Daan's Droomijs" />
    <h1>Daan's droomijs voor de feestdagen</h1>
    <p>
      Daan’s droomijs heeft de lekkerste ijstaarten voor de feestdagen gemaakt.
      Of ga je liever voor onze overheerlijke ijstapas?
      Bestellen kan tot en met <strong>dinsdag 22 december 16.00</strong>.
      Je kunt je bestelling op komen halen in de ijssalon op 23 of 24 december tussen 12.00-16.00.
    </p>
    <h3>IJs tapas</h3>
    <p>
      Heb jij ook zo’n zin in Daan’s Droomijs in de winter? Is jouw favoriet Witte chocolade ijs met die overheerlijke brokken witte chocolade?
      En dan die kersen over het yoghurtijs? Vanaf nu kan het allemaal bij jou thuis!
    </p>
    <h3>IJstaart</h3>
    <p>
      Wij hebben voor jullie luxe ijstaartjes ontwikkeld waarmee je echt iets op tafel zet.
      Ook hip en happening om cadeau te geven! Met 4-6 personen kan je nu genieten van ons overheerlijke ijs in een winters jasje.
      Je krijgt de ijstaart in een luxe stolp.
    </p>
  </div>
);

export default HomeBlock;
