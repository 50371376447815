export const formatPrice = (x, currency) => {
  switch (currency) {
    case 'BRL':
    case 'EUR':
      return x.toFixed(2).replace('.', ',');
    default:
      return x.toFixed(2);
  }
};

export const orderAPI = 'https://0bcub1eje3.execute-api.eu-central-1.amazonaws.com/prod/order';
