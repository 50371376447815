import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
// import { updateCart } from '../../services/total/actions';

import OrderForm from './OrderForm';

import './style.scss';
import ShoppingCart from './ShoppingCart';
import Thanks from './Thanks';

const flows = {
  BASKET: 'basket',
  CHECKOUT: 'checkout',
  THANKS: 'thanks',
};

class FloatCart extends Component {
  static propTypes = {
    cartTotal: PropTypes.object.isRequired,
    cartProducts: PropTypes.array.isRequired,
  };

  state = {
    isOpen: false,
    flow: flows.BASKET,
  };

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };

  nextFlow = (flow) => {
    console.log(`switching to flow: ${flow}`);
    this.setState({ flow });
  }

  clearBasket = () => {
    // const { cartProducts } = this.props;
    // console.log('clearing basket!');
    // cartProducts.splice(0, cartProducts.length);
    // updateCart(cartProducts);
    this.setState({ flow: flows.BASKET, isOpen: false });
  }

  render() {
    const { cartTotal } = this.props;
    const { isOpen, flow } = this.state;

    let classes = ['float-cart'];

    if (!!isOpen) {
      classes.push('float-cart--open');
    }

    return (
      <div className={classes.join(' ')}>
        {/* If cart open, show close (x) button */}
        {isOpen && (
          <div
            onClick={() => this.closeFloatCart()}
            className="float-cart__close-btn"
          >
            X
          </div>
        )}

        {/* If cart is closed, show bag with quantity of product and open cart action */}
        {!isOpen && (
          <span
            onClick={() => this.openFloatCart()}
            className="bag bag--float-cart-closed"
          >
            <span className="bag__quantity">{cartTotal.productQuantity}</span>
          </span>
        )}

        {flow === flows.BASKET && (
          <ShoppingCart onSubmit={() => this.nextFlow(flows.CHECKOUT)} onOpenFloatCart={() => this.openFloatCart()} />
        )}
        {flow === flows.CHECKOUT && (
          <OrderForm onSubmit={() => this.nextFlow(flows.THANKS)} />
        )}
        {flow === flows.THANKS && (
          <Thanks onSubmit={() => this.clearBasket()} />
        )}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartTotal: state.total.data,
  cartProducts: state.cart.products,
});

export default connect(
  mapStateToProps,
  {}
)(FloatCart);
