import React from 'react';

import './style.css';

const FooterBlock = () => (
  <div className="footer-block">
    <h3>Ons Adres</h3>
    <p>
      Daan's Droomijs<br/>
      Onder de Boompjes 19<br/>
      3417 HE Montfoort<br/>
      <a href="mailto:info@daansdroomijs.nl">info@daansdroomijs.nl</a>
    </p>
  </div>
);

export default FooterBlock;
